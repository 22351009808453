.leaflet-container {
    width: 100vw;
    height: 100vh;

}
.GPS-marker {
    width: 12px;
    height: 12px;
    background-color: blue; /* 青色 */
    border-radius: 50%; /* 円形 */
    border: 2px solid white; /* 外枠 */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 影 */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}