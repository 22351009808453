.main{
    margin: 10px;
    padding: 10px;
    background-color: white;
    max-width: 600px;
    text-align: center;
    margin: 0 auto;
}
h1 {
    color: #364e96;/*文字色*/
    border: solid 3px #364e96;/*線色*/
    padding: 0.5em;/*文字周りの余白*/
    border-radius: 0.5em;/*角丸*/
  }
h2 {
    color: #6594e0;/*文字色*/
    /*線の種類（点線）2px 線色*/
    border-bottom: dashed 2px #6594e0;
}
th,td {
    border: solid 1px;  /* 枠線指定 */
    padding: 10px;      /* 余白指定 */
}

table {
    border-collapse:  collapse; /* セルの線を重ねる */
    margin: 0 auto;
}
iframe{
    margin-top: 20px;
    width:100%;
    height:300px;
    border: 0
}
a:link { color: black; }
a:visited { color: black; }
a:hover { color: #ff0000; }
a:active { color: #ff8000; }
